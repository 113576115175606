const tools = {
    ajxGet(url, options) {
      tools.ajx('GET', url, options)
    },

    ajxPost(url, options) {
      tools.ajx('POST', url, options)
    },

    ajx(method, url, options) {
      let req = null;
      if (url) {
        req = new XMLHttpRequest();

        req.onreadystatechange = function () {

          if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            if (options.fallback) {
              options.fallback(req.response);
            }
          }
        };

        let params = null;
        if (options.formData && options.formData instanceof FormData) {
          params = '';
          for (let entry of options.formData.entries()) {
            params += entry[0] + '=' + entry[1] + '&';
          }
          params = params.substr(0, params.length - 1);
        }

        if (options.replaceHistory) {
          tools.replaceHistoryState(url);
        }

        req.open(method, url, true);
        if(params) {
          req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        }
        req.responseType = options.responseType || 'text';
        req.send(params);
      } else if(options.fallback){
        options.fallback({error: 'url not provided'})
      }
      return req;
    },

    each(list, fn) {
        if(list && fn) {
            for(let i = 0; i < list.length; i++) {
                fn(list[i]);
            }
        }
    },

    getCurrentURL : function(excludeParams){
        var url = window.location.href;
        if(excludeParams){
            var i = url.indexOf("?");
            if(i>-1){
                url = url.substring(0,i);
            }
            var j = url.indexOf("#");
            if(j>-1){
                url = url.substring(0,j);
            }
        }
        return url;
    },

    getParameterByName: function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },

    isEditMode() {return document.body.classList.contains('editmode')}
};

module.exports = tools;

