import fancybox from '@fancyapps/fancybox';
// import superfish from 'superfish';
// import "vendor/isotope.pkgd.min.js";
import Isotope from "./vendor/isotope.pkgd.min.js";

const tools = require('./tools');
import Map from './components/map';
import DocumentList from "./components/documentList";

import LazyImg from "./lazy/LazyImg";
import LazyImgSlow from "./lazy/LazyImgSlow";
import LazyBackground from "./lazy/LazyBackground";
import LazyBackgroundSlow from "./lazy/LazyBackgroundSlow";

import autocomplete from 'jquery-ui/ui/widgets/autocomplete'
import $ from 'jquery';

let historyManualStateChange = true;
const sukoaProject = {
  init() {
    document.getElementsByTagName('html')[0].classList.remove('no-js');
    sukoaProject.navigationInteraction();
    sukoaProject.fancyBoxInteraction();
    sukoaProject.draggableInteraction();
    sukoaProject.bubbleInteraction();
    sukoaProject.aggregationClickInteraction();
    sukoaProject.initExpandableFilter();
    sukoaProject.clickMapInteraction();
    sukoaProject.dbSearchInteraction();
    sukoaProject.autocompleteSearch();
    sukoaProject.initMap();
    sukoaProject.initDocumentList();
    sukoaProject.initBlogSubscriptionForm();
    sukoaProject.lazy();
    sukoaProject.initBlogGrid();
    navigation.init();
  },

  navigationInteraction() {
    // $("#nav-main ul").superfish();

    const btn = document.querySelector('#menu-btn');
    if (btn) {
      btn.addEventListener('click', e => {
        if (document.body.classList.contains('navexp')) {
          document.body.classList.remove('navexp');
        } else {
          document.body.classList.add('navexp');
        }
      })
    }

    const heading = document.querySelector('#header_first_row');
    if (heading) {
      sukoaProject.initNavigation(heading);
    }
  },

  navBlocked: false,
  navHidden: false,
  headerElt: null,

  initNavigation(elt) {
    sukoaProject.headerElt = elt;
    let scrollPos = 0;
    let lastScrollDown = false;


    window.addEventListener('scroll', () => {
      if (!sukoaProject.navBlocked) {
        let currentPos = document.body.getBoundingClientRect().top;
        lastScrollDown = currentPos <= scrollPos;

        if (Math.abs(scrollPos - currentPos) > 50) {

          if (scrollPos < -100 && lastScrollDown) {
            sukoaProject.hideNav();

          } else if (!lastScrollDown) {
            sukoaProject.showNav();
          }
          scrollPos = currentPos;
        }
      }
    });

    let navElt = document.getElementById('nav-main').getElementsByClassName('sf-menu')[0];

    if (navElt) {
      tools.each(navElt.querySelectorAll('li'), elt => {
        elt.addEventListener("click", (e) => {
          if (e.target === elt) {
            if (document.documentElement.clientWidth <= 640) {
              if (elt.classList.contains('open')) {
                elt.classList.remove('open');
              } else {
                if (elt.parentElement.parentElement.classList.contains('active') || elt.parentElement.parentElement.classList.contains('open')) {
                  elt.classList.add('open');
                } else {
                  tools.each(navElt.getElementsByClassName('open'), openElt => {
                    openElt.classList.remove('open');
                  });
                  elt.classList.add('open');
                }
              }
            }
          }
        })
      });
    }

    // tools.each(navElt.querySelectorAll('li a'), elt => {
    //     elt.addEventListener("click", (e) => {
    //         if (document.documentElement.clientWidth <= 640){
    //             if (elt.parentElement.classList.contains('active') || elt.parentElement.classList.contains('open')){
    //             } else {
    //                 e.preventDefault();
    //                 elt.parentElement.classList.add('open');
    //             }
    //         }
    //     })
    // });
  },

  hideNav(andBlock) {
    sukoaProject.navBlocked = false;
    if (!sukoaProject.navHidden) {
      sukoaProject.headerElt.style.transform = 'translateY(-100%)';
      sukoaProject.navHidden = true;
    }
    if (andBlock) {
      sukoaProject.navBlocked = true;
    }
  },

  showNav() {
    sukoaProject.navBlocked = false;
    if (sukoaProject.navHidden) {
      sukoaProject.headerElt.style.transform = 'translateY(0)';
      sukoaProject.navHidden = false;
    }
  },

  freezeNav() {
    sukoaProject.navBlocked = true;
  },

  releaseNav() {
    setTimeout(() => sukoaProject.navBlocked = false, 200);
  },

  fancyBoxInteraction() {
    $("a.fancyBox").fancybox();
  },

  draggableInteraction() {
    if (document.getElementsByClassName("mgnlMainbar").length > 0) {
      document.getElementsByClassName("draggable").draggable({
        containment: "parent",
        stop: function (event, ui) {
          let uuid = $(this).attr('id');
          if (uuid.indexOf("cme_") > 0) {
          }
          let left = ui.position.left;
          let top = ui.position.top;
          $.get(basepath + "/custom/admin/persistdata.jsp", {uuid: uuid, bubbleTop: top, bubbleLeft: left});
        }
      });
    }
    $(".admin_cme").mouseenter(function () {
      let t = $(this);
      let id = t.attr("id");
      $(".clickMapEntry").fadeTo(0, 0.3);
      $("#" + id.substring(4)).fadeTo(0, 1);
    }).mouseleave(function () {
      $(".clickMapEntry").fadeTo(0, 1);
    });
  },

  bubbleInteraction() {
    $('div.bubble-wrap').each(function () {
      let height = $(this).height() * 0.9;
      let text = $(this).find(".bubble-overlay-text");
      let innerHeight = text.height();
      let top = Math.max((height - innerHeight) / 2, 5);
      text.css("top", top);
    });
  },

  aggregationClickInteraction() {
    $(".aggregation-entry").each(function () {
      let t = $(this);
      let ml = t.find(".more-link a");
      if (ml.length > 0) {
        let l = ml.attr("href");
        t.click(function (e) {
          if (l.indexOf("http") == 0) {
            e.preventDefault();
            window.open(l);
          } else {
            document.location.href = l;
          }
        }).addClass("clickable-entry");
      }
    });
  },

  clickMapInteraction() {
    $(".clickMapEntry").mouseenter(function () {
      let t = $(this);
      if (!t.hasClass("draggable")) {
        let id = t.attr("id");
        let aggid = "agg" + id;
        $(".clickmap_aggregation").each(function () {
          if ($(this).attr("id") != aggid) {
            $(this).hide();
          }
        });
        $("#" + aggid).fadeIn().css("top", (t.position().top + 18) + "px").css("left", (t.position().left + 18) + "px");
      }
    }).mouseleave(function () {
      //        let t = $(this);
      //        let id = t.attr("id");
      //        $("#agg"+id).fadeOut();

    });
    $(".clickmap_aggregation").mouseleave(function () {
      $(this).fadeOut();
    });
    $(".detail_link").click(function () {
      let t = $(this).parent().parent();
      t.hide();
      let id = "dtl" + t.attr("id").substring(3);
      $.fancybox({
        showCloseButton: false,
        content: "<div class='clickMapDetailDisplay'>" + $("#" + id).html() + "</div>",
        onComplete: function () {
          $(".close_fancy").click(function () {
            $.fancybox.close();
          });
        }
      });

      //        .slideDown();
    });
  },

  printPage() {
    window.print();
  },

  initExpandableFilter() {
    let formFilterContainer = $(".dbsearch-form-filter");
    if (formFilterContainer) {
        sukoaProject.updateExpandableFilter();
        formFilterContainer.find(".detailed-search-subheader").click(function () {
            let header = $(this);
            let checkboxes = header.next(".checkbox-div");
            checkboxes.slideToggle();
            header.toggleClass("open");
        });
    }
  },

  updateExpandableFilter() {
      let formFilterContainer = $(".dbsearch-form-filter");
      if (formFilterContainer) {
          formFilterContainer.find(".detailed-search-subheader").each(function (index) {
              let header = $(this);
              let checkboxes = header.next(".checkbox-div");
              checkboxes.hide();
              header.removeClass("open");

              let headerCounter = header.find(".counter");
              let checkCounter = 0;
              checkboxes.find("input[type=checkbox]").each(function (index) {
                  if ($(this).is(":checked")) {
                      checkCounter++;
                  }
              });
              if (checkCounter > 0) {
                  headerCounter.text(` (${checkCounter})`);
                  //if there is a selection: open the menu right away
                  setTimeout(() => header.click(), 200);
              } else {
                  headerCounter.text("");
              }
          });
      }
  },

  dbSearchInteraction() {
    let interactionTimeout = undefined
    let body = $('body');

    $(".expandable-title").next(".expandable-title").hide();

    body.on('click', ".expandable-title", function () {
      let t = $(this);
      t.nextAll(".expandable-area:first").slideToggle(250);
      if (t.next(".expandable-title").length || $(this).prev(".expandable-title").length) {
        t.toggle();
        t.next(".expandable-title").toggle();
        t.prev(".expandable-title").toggle();
      }
    });

    $("#searchfilter").click(function () {
      $("#submitForm").click();
    });

    $(".searchbox-home form, #db_search_form").submit(function () {
      $('.dbsearch-form-wrapper .loader').show();
      $('#hidden-content').fancybox({
        showCloseButton: false
      });
    });

    // check if history state is supported
    if (window.History) {

      // uses a flag to check if browser back/forward is clicked.
      window.onpopstate = function (event) {
        let state = event.state;
        if (historyManualStateChange) {
          window.location.replace(state.url);
        }
        historyManualStateChange = true;
      }

      let currentBaseUrl = location.protocol + '//' + location.host + location.pathname;

      // bind listners to form and fetch results dynamically
      let searchForm = document.getElementById("db_search_form");
      if (searchForm) {
        searchForm.addEventListener("change", ajaxFormSubmit);
        searchForm.addEventListener("submit", ajaxFormSubmit);
      }


      function ajaxFormSubmit(e) {
          if (interactionTimeout !== undefined) {
              window.clearTimeout(interactionTimeout)
          }

          interactionTimeout = window.setTimeout(() => {
            e.preventDefault();
            let t = $(this);
            let params = t.serialize();
              $('.dbsearch-form-wrapper .loader').show();
            let searchResults = $('.dbsearch-results').fadeOut(200, function () {

            $.ajax({
                url: '?ajax=true&' + params,
                success: function (html) {
                  let $html = $(html);
                  let results = $html.find('.dbsearch-results');
                  searchResults.html(results.html()).promise().done(function () {
                    $(this).fadeIn(200, function () {
                      $('.dbsearch-form-wrapper .loader').hide();
                    });
                    $(".expandable-title").next(".expandable-title").hide();
                    historyManualStateChange = false;
                    window.history.pushState(null, null, currentBaseUrl + '?' + params);
                  });

                  sukoaProject.updateExpandableFilter();
                }
              });
            })},1000)
      }

      // bind listners to anchors and fetch content dynically
      body.on('click', '.dbsearch-item .dbsearch-item-header a', function (e) {
        e.preventDefault();
        let t = $(this);
        let container = t.closest('.dbsearch-item');
        let url = t.attr('href');
        if (!container.hasClass('active')) {
          if (url) {
            $.ajax({
              url: url,
              success: function (html) {
                historyManualStateChange = false;
                window.history.pushState(null, null, url);

                let $html = $(html);
                $('.group-detail').slideUp(function () {
                  $(this).remove();
                });
                let groupDetail = $html.find('.group-detail');
                if (groupDetail) {
                  groupDetail.hide().find('.group-header').remove();
                  groupDetail.find('.back-link').remove();
                  //groupDetail.find('.back-link').remove();
                  container.append(groupDetail);
                  groupDetail.slideDown();
                }
                $('.dbsearch-item').removeClass('active');
                container.addClass('active');
              }
            });
          }
        } else {
          container.find('.group-detail').slideUp(function () {
            $(this).remove();
            container.removeClass('active');
            window.history.pushState(null, null, url);
          });
        }
      });
    }

    let themeLists = document.getElementsByClassName('theme-list');
    tools.each(document.getElementsByClassName('theme-list'), themeList => {
      tools.each(themeList.getElementsByClassName('letter-link'), letterAnchor => {
        letterAnchor.addEventListener('click', (e) => {
          e.preventDefault();
          document.getElementsByName(letterAnchor.getAttribute('href').replace("#", ""))[0].scrollIntoView({
            behavior: 'smooth'
          });
          // document.querySelector(letterAnchor.getAttribute('href')).scrollIntoView({
          //     behavior: 'smooth'
          // });
        });
      });
    });


      body.on('change', '.regionselect input[type="checkbox"]', function (e) {
          let regionCheckboxes = e.target.parentElement.parentElement.querySelectorAll('input[type="checkbox"]');
          let someCheck = [...regionCheckboxes].some(item => item.checked)
          let allCheck = [...regionCheckboxes].every(item => item.checked)

          console.log(`all: ${allCheck} some: ${someCheck}`)
          // if (allCheck) {
          //     e.target.parentElement.parentElement.parentElement.querySelectorAll("li.canton-input input")[0].indeterminate = false
          //     e.target.parentElement.parentElement.parentElement.querySelectorAll("li.canton-input input")[0].checked = true
          // } else if (someCheck) {
          //     e.target.parentElement.parentElement.parentElement.querySelectorAll("li.canton-input input")[0].indeterminate = true
          //     e.target.parentElement.parentElement.parentElement.querySelectorAll("li.canton-input input")[0].checked = false
          // }
      })
  },

  autocompleteSearch() {
    let searchAutocompleteKeywordsDiv = document.getElementById("search_autocomplete_keywords");
    if (searchAutocompleteKeywordsDiv) {
      let searchAutocompleteKeywords = searchAutocompleteKeywordsDiv.dataset.searchAutocompleteKeywords;
      searchAutocompleteKeywords = searchAutocompleteKeywords.split(",");
      if (typeof searchAutocompleteKeywords !== "undefined" && searchAutocompleteKeywords && searchAutocompleteKeywords.length > 0) {
        let form = $('#db_search_form, .searchbox-home form');

        form.find('.input-container .inputfield').autocomplete({
          source: searchAutocompleteKeywords,
          delay: 1,
          minLength: 1,
          autoFocus: false,
          select: function (event, ui) {
            form.find('.input-container .inputfield').val(ui.item.value);
            form.submit();
          },
          position: {
            of: $('#db_search_form .input-container, .searchbox-home form .input-container')
          }
        });
        form.click(function () {
          form.find('.input-container .inputfield').autocomplete('close');
        });
      }
    }
  },

  initMap() {
    let maps = document.getElementsByClassName('map-component');
    if (maps.length > 0) {
      tools.each(maps, map => new Map(map));
    }
  },

  initDocumentList() {
    let documentLists = document.getElementsByClassName('document-list-component');
    if (documentLists) {
      tools.each(documentLists, documentList => new DocumentList(documentList));
    }
  },

  initBlogSubscriptionForm(){
    const blogSubscriptionForm = document.querySelector('.blogsubscriptionform');

    if(blogSubscriptionForm){
      blogSubscriptionForm.addEventListener('submit', e =>{
        e.preventDefault();
        let formdata = new FormData(e.target);
        tools.ajxPost(blogSubscriptionForm.getAttribute('action'), {
          formData: formdata,
          fallback: r => {
            let response = JSON.parse(r);
            if (response.success) {
              blogSubscriptionForm.parentElement.classList.add('success');
            } else {
              blogSubscriptionForm.parentElement.classList.add('error');
            }
          }
        });
      })
    }

  },

  initBlogGrid() {
    const grid = document.querySelector('#blogGridPosts');
    if (grid) {


      const isotope = new Isotope(grid, {
        itemSelector: ".post",
        percentPosition: true,
        transitionDuration: 0,
        layoutMode: "masonry",
        masonry: {
          horizontalOrder: true
        },
        getSortData: {
          date: '[data-timestamp] parseInt'
        },
        sortBy: 'date',
        sortAscending: false
      });

      window.addEventListener('load', (event) => {

        isotope.arrange();
      });
    }
  },

  lazy() {
    new LazyImg();
    new LazyImgSlow();
    new LazyBackground();
    new LazyBackgroundSlow();
  }
};


document.addEventListener('DOMContentLoaded', () => {
  sukoaProject.init();
});

window.sukoaUtils = {
  dcmadr: function (nnnn) {
    var a = "";
    for (var i = 0; i < nnnn.length; i++) {
      if (i % 3 === 0) {
        a += String.fromCharCode(nnnn.substr(i, 3));
      }
    }
    location.href = (a);
  }
};

// mobile {
let navigation = {
  init: function () {
    navigation.bind();
  },
  bind: function () {
    let body = $('body');
    // fix resize bug

    if (!body.hasClass('shbe')) {
      $('#nav-mobile ul > .l1 a').click(function () {
        let li = $(this).parent();
        let children = li.find('> ul.sf-menu');
        if (children.length > 0) {
          children.toggle();
          return false;
        }
        $(this).trigger('click');
      });
    }

    $("[data-menu-open]").click(function () {
      let t = $(this);
      let target = t.attr('data-menu-open');
      let menu = $(target);
      (menu.hasClass("is-active") === true) ? menu.removeClass("is-active") : menu.addClass("is-active");
      if (menu.hasClass("is-active")) {
        menu.slideDown();
      } else {
        menu.slideUp();
      }
    });
  }
};
