const loadGoogleMapsApi = require('load-google-maps-api');
const tools = require('../tools');

class Map {
    constructor(elt) {
        this.elt = elt;
        this.gmap = null;
        this.markersList = [];
        this.map = elt.getElementsByClassName('map')[0];
        this.mapComponents = elt.getElementsByClassName('map-entry-component');

        this.createMap(this.map);
        this.initListDetail();
    }

    createMap(mapElement) {
        let apikey = mapElement.getAttribute('data-key');
        let mapLat = mapElement.getAttribute('data-map-lat');
        let mapLng = mapElement.getAttribute('data-map-lng');
        let mapZoom = mapElement.getAttribute('data-map-zoom');

        loadGoogleMapsApi({ key: apikey }).then(googleMaps => {
            this.gmap = new googleMaps.Map(mapElement, {
                center: { lat: parseFloat(mapLat), lng: parseFloat(mapLng) },
                zoom: parseInt(mapZoom)
            });

            this.initMap();
        });
    }

    initMap() {
        if(markersMap) {
            tools.each(markersMap, marker => this.markersList.push(new MapMarker(marker)));
        }
        tools.each(this.markersList, marker => {
            this.place(marker);
        });
    }

    place(marker) {
        if (marker.hasLatLng()){
            let mapMarker = new google.maps.Marker({
                position: marker.getLatLng(),
                map: this.gmap,
                title: marker.header,
                icon: marker.icon
            });

            let infoWindow = new google.maps.InfoWindow({
                content:'<div class="info-window">'+
                    '<div>'+ marker.region + '</div>' +
                    '<h4 class="firstHeading" onclick="document.getElementById(\''+(marker.detailLink).replace("#", "")+'\').click();"><a href="'+marker.detailLink+'"'+marker.titleStyle+'>' + marker.name + '</a></h4>'+
                    '<div>'+ marker.detailText + '</div>' +
                    '</div>'
            });
            mapMarker.addListener('click', function() {
                infoWindow.open(this.gmap, mapMarker);
            });

            if(marker.isTarget) {
                this.gmap.setCenter(marker.getLatLng());
                infoWindow.open(this.gmap, mapMarker);
                document.getElementById((marker.detailLink).replace("#", "")).click();
            }
        }
    }

    initListDetail(){
        tools.each(this.mapComponents, mapComponent => {
            this.mapComponentListener(mapComponent);
        });
    }

    mapComponentListener(component){
        component.addEventListener('click', (evt) => {
            $('.map-detail-wrapper').slideUp();
            if (!component.classList.contains("is-open")){
                // remove the old before to add the new
                $(".map-detail-wrapper").slideUp(e => {
                    $(this).remove();
                })
                $(".map-entry-component.is-open").removeClass("is-open");
                $(".map-entry-component.light-background-color").removeClass("light-background-color");
                let currentIndex = Array.prototype.indexOf.call(this.mapComponents, component) + 1;
                let afterElmt = this.mapComponents[this.getAfterElmtIndex(currentIndex)];
                let componentDetailContent = component.getElementsByClassName('map-entry-detail');
                if (componentDetailContent.length > 0){
                    componentDetailContent = componentDetailContent[0].innerHTML;
                    let componentDetail = document.createElement("span");
                    componentDetail.innerHTML = componentDetailContent;
                    componentDetail.classList.add("map-detail-wrapper","light-background-color");
                    component.parentElement.insertBefore(componentDetail, afterElmt);
                    $(componentDetail).slideDown();
                    component.classList.add("is-open", "light-background-color");
                }
            } else {
                component.classList.remove("is-open", "light-background-color");
            }
        });
    }

    getAfterElmtIndex(currentIndex){
        let i = 0;
        if (window.innerWidth > 768){
            while (!this.fits(3, currentIndex+i)){
                i++;
            }
        } else if (window.innerWidth >= 640){
            while (!this.fits(2, currentIndex+i)){
                i++;
            }
        }

        return currentIndex+i;
    }

    fits(x, y) {
        if (Number.isInteger(y / x)) {
            return true;
        }
        return false;
    }

}

export default Map;

class MapMarker {
    constructor(json) {
        this.region = json.region;
        this.name = json.name;
        this.lat = json.lat;
        this.lng = json.lng;
        this.detailText = json.detailText;
        this.detailLink = json.detailLink;
        this.isTarget = json.isTarget;
        this.iconColor = json.iconColor;
        this.titleStyle = this.getTitleStyle();
        this.icon = {
            path: 'M4 12 A12 12 0 0 1 28 12 C28 20, 16 32, 16 32 C16 32, 4 20 4 12 M11 12 A5 5 0 0 0 21 12 A5 5 0 0 0 11 12 Z',
            scale: 1,
            fillOpacity: 1,
            fillColor: json.iconColor,
            strokeColor: json.iconColor,
            size: new google.maps.Size(26, 34),
            // The origin for this image is (0, 0).
            origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new google.maps.Point(13, 34)
        }
    }

    getTitleStyle() {
        let titleStyle = '';
        if (this.iconColor != '#ffffff'){
            titleStyle = ' style="color:'+this.iconColor+';"';
        }
        return titleStyle;
    }
    getLatLng() {
        return new google.maps.LatLng(parseFloat(this.lat), parseFloat(this.lng));
    }

    hasLatLng(){
        return (this.lat != "" && this.lng != "")
    }
}
