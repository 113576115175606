import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue';
let vueResource = require('vue-resource');
Vue.use(vueResource.default);

class DocumentList {
    constructor(elt) {
        this.elt = elt;
        this.documentRoot = elt.dataset.documentRoot;
        this.siteBaseUrl = elt.dataset.baseUrl;
        this.init();
    }

    init(){
        let docList = this;

        Vue.component('document-tree', {
            props: ['doc', 'currentlang', 'rootpath', 'childcount'],
            template: '<li v-bind:class="{ open: isActive }">\n' +
                '<a v-on:click="updateRootPath"><span v-if="doc[\'name\'+currentlang]">{{ doc[\'name\'+currentlang] }}</span><span v-else>{{ doc[\'name\'] }}</span> <span v-if="childcount && childcount != \'\'"> ({{ childcount }}) </span></a>\n' +
                '<ul v-if="doc.nodes">\n' +
                '<document-tree v-for="doc in doc.nodes" v-bind:key="doc.id" v-bind:currentlang="currentlang" v-bind:rootpath="rootpath" v-on:update-root-path="transmitUpdateRootPath" v-bind:childcount="doc.childCount" v-bind:doc="doc" v-if="doc.type === \'mgnl:folder\'"></document-tree>\n' +
                '</ul>\n' +
                '</li>',

            methods: {
                transmitUpdateRootPath: function(data) {
                    this.$emit('update-root-path', data)
                },

                updateRootPath: function($event) {
                    if ($event.target.parentElement.parentElement.classList.contains('open')){
                        let openedChilds = $event.target.parentElement.parentElement.querySelectorAll(".open");
                        if (openedChilds.length > 0){
                            this.$emit('update-root-path', this.doc.path);
                            openedChilds.forEach(el => el.classList.remove('open'));
                        } else {
                            let lastIndex = this.doc.path.lastIndexOf("/");
                            let newPath= this.doc.path.slice(0,lastIndex+1);
                            this.$emit('update-root-path', newPath);
                            $event.target.parentElement.parentElement.classList.toggle('open');
                        }
                    } else {
                        this.$emit('update-root-path', this.doc.path);
                        let activeEls = document.querySelectorAll(".open");
                        activeEls.forEach(activeEl => {
                            if (!activeEl.contains($event.target)){
                                activeEl.classList.remove('open');
                            }
                        });
                        $event.target.parentElement.parentElement.classList.toggle('open');
                    }
                }
            },
            computed: {
                isActive: function() {
                    return (this.rootpath != '' && this.rootpath.includes(this.doc.path));
                },
            }
        })

        new Vue({
            el: docList.elt,
            data: {
                documents: [],
                documentStructure: [],
                languages: [],
                selectedLanguage: '',
                sortOrder: 'chrono_reverse',
                rootPath: docList.documentRoot,
                q:''
            },
            mounted: function() {
                this.loadDocuments();
                this.getFilterParam();
            },
            methods: {
                resetSearch: function() {
                    this.q = ''
                },
                loadDocuments: async function() {
                    let maxPages = 0
                    let currentPage = 0
                    let limit = 0

                    let base = docList.siteBaseUrl+'/.rest/documents';
                    let url = base

                    if (this.q !== '') {
                        url = base +"?q=" + "*"+this.q+ "*"
                    }

                    if (this.$http){
                        await callHttp(this, url)
                    }

                    while (maxPages > currentPage) {
                        currentPage++;
                        url = base + "?"

                        if (this.q !== '') {
                            url = url + "q=" + "*" + this.q + "*" + "&"
                        }
                        url = url + "offset=" + limit * currentPage
                        await callHttp(this, url)
                    }

                    this.documents = this.documents.filter(function (element) {
                        let hasContent = element.hasOwnProperty("documentContent")
                        if (!hasContent) {
                            console.log(element['@path'])
                        }
                        return hasContent
                    })



                    async function callHttp(_this, url) {
                         await _this.$http.get(url).then(response => {
                            let languages = [];
                            let documents = response.body.results;
                            maxPages = Math.floor(response.body.total / response.body.limit)
                            limit = response.body.limit
                            documents.forEach(function (item, index, arr) {
                                let lang = item.language + "";
                                lang = lang.replace(/[^\w\s]/gi, '');
                                if (!languages.includes(lang) && lang !== "undefined") {
                                    languages.push(lang);
                                }
                                if (item.date) {
                                    let newDate = new Date(item.date);
                                    item.dateString = ("0" + newDate.getDate()).slice(-2) + "." + ("0" + (newDate.getMonth() + 1)).slice(-2) + "." + newDate.getFullYear();
                                }
                                if (item.user) {
                                    let userIsID = item.user.match('[\\dabcdef]{8}-[\\dabcdef]{4}-[\\dabcdef]{4}-[\\dabcdef]{4}-[\\dabcdef]{12}');
                                    if (userIsID) {
                                        item.user = ""
                                    }
                                }
                            });
                            _this.documents = documents;
                            console.log("------loadDocuments")
                            console.log(documents)
                            _this.languages = languages;
                            _this.loadDocumentStructure();
                        });
                    }


                },
                loadDocumentStructure: function () {
                    let $this = this;
                    let url = docList.siteBaseUrl+'/.rest/nodes/v1/sh-document/documentStructure'+docList.documentRoot.replace("/documentStructure", "")+'?depth=4&excludeNodeTypes=rep%3Asystem%2Crep%3AAccessControl&includeMetadata=false';
                    if (this.$http){
                        this.$http.get(url).then(response => {
                            let documentStructure = this.denormNameValues(response.body);
                            this.documentStructure = documentStructure;
                            $this.updateChildCount(this.documents);
                        });
                    }
                },
                getFilterParam: function () {
                    if (window.location.search){
                        let searchParams = new URLSearchParams(window.location.search);
                        if (searchParams){
                            let sortParam = searchParams.get("sort");
                            if (sortParam){
                                this.sortOrder = decodeURIComponent(sortParam);
                            }
                            let langParam = searchParams.get("lang");
                            if (langParam){
                                this.selectedLanguage = decodeURIComponent(langParam);
                            }
                            let rootParam = searchParams.get("root");
                            if (rootParam){
                                this.rootPath = decodeURIComponent(rootParam);
                            }
                        }
                    }
                },
                denormNameValues: function(item){
                    let $this = this;
                    let itemNodes = item.nodes;
                    if (itemNodes){
                        itemNodes.forEach( function(tmp, index, arr) {
                            if (tmp.type === "mgnl:folder"){
                                tmp.properties.forEach( function(tmpProps){
                                    if(tmpProps.name == "name") {
                                        arr[index].name = tmpProps.values[0];
                                    } else if(tmpProps.name == "name_fr") {
                                        arr[index].nameFR = tmpProps.values[0];
                                    } else if(tmpProps.name == "name_it") {
                                        arr[index].nameIT = tmpProps.values[0];
                                    }
                                });
                                $this.denormNameValues(tmp);
                            }
                        });
                    }
                    return itemNodes;
                },
                humanFileSize: function(size) {
                    let i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
                    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
                },
                updateRootPath: function (selectedPath) {
                    this.rootPath = selectedPath;
                    window.history.replaceState('', '', this.updateURLParameter(window.location.href, "root", encodeURIComponent(selectedPath)));
                },
                updateSortParam: function () {
                    window.history.replaceState('', '', this.updateURLParameter(window.location.href, "sort", encodeURIComponent(this.sortOrder)));
                },
                updateLangParam: function () {
                    window.history.replaceState('', '', this.updateURLParameter(window.location.href, "lang", encodeURIComponent(this.selectedLanguage)));
                },
                updateURLParameter: function (url, param, paramVal){
                    let newAdditionalURL = "";
                    let tempArray = url.split("?");
                    let baseURL = tempArray[0];
                    let additionalURL = tempArray[1];
                    let temp = "";
                    if (additionalURL) {
                        tempArray = additionalURL.split("&");
                        for (let i=0; i<tempArray.length; i++){
                            if(tempArray[i].split('=')[0] != param){
                                newAdditionalURL += temp + tempArray[i];
                                temp = "&";
                            }
                        }
                    }

                    let rows_txt = temp + "" + param + "=" + paramVal;
                    return baseURL + "?" + newAdditionalURL + rows_txt;
                },
                getChildCount: function (documentList, tempRootPath) {
                    let childCountList = documentList.filter((doc) => {
                        let isFiltered = true;
                        if (tempRootPath){
                            if (doc['@path'].startsWith(tempRootPath)){
                                isFiltered = true;
                            } else {
                                return false;
                            }
                        }
                        return isFiltered;
                    });
                    // console.log(childCountList.length + "");
                    return childCountList.length + "";
                },
                updateChildCount(documentList){
                    let $this = this;
                    this.documentStructure.forEach( function(item, index, arr) {
                        let childCount = $this.getChildCount(documentList, item.path);
                        item.childCount = childCount;
                        $this.childCountLoop(documentList, item);
                    });
                    return this.documentStructure;
                },
                childCountLoop: function(documentList, item){
                    let $this = this;
                    if (item){
                        let itemNodes = item.nodes;
                        if (itemNodes){
                            itemNodes.forEach( function(tmp, index, arr) {
                                if (tmp.type === "mgnl:folder"){
                                    let childCount = $this.getChildCount(documentList, tmp.path);
                                    tmp.childCount = childCount;
                                    $this.childCountLoop(documentList, tmp);
                                }
                            });
                        }
                        return itemNodes;
                    }
                },
                getFileInformation: function(node) {
                    let arr = []
                    arr.push(node.dateString)
                    arr.push(node.user)
                    arr.push(node.language)

                    arr = arr.filter(function( element ) {
                        return element !== undefined;
                    });

                    return arr.join(' | ')
                }
            },
            computed:{
                filteredDocuments: function(){
                    let filteredDocuments = this.documents.filter((doc) => {
                        let isFiltered = true;
                        if (this.selectedLanguage){
                            if (doc.language === this.selectedLanguage){
                                isFiltered = true;
                            } else {
                                return false;
                            }
                        }
                        if (this.rootPath){
                            if (doc['@path'].startsWith(this.rootPath)){
                                isFiltered = true;
                            } else {
                                return false;
                            }
                        }
                        return isFiltered;
                    });
                    if (this.sortOrder){
                        if (this.sortOrder.includes("alpha")){
                            filteredDocuments.sort(function (a, b) {
                                if (a.name < b.name)
                                    return -1;
                                if (a.name > b.name)
                                    return 1;
                                return 0;
                            });
                            if (this.sortOrder === "alpha_reverse"){
                                filteredDocuments.reverse();
                            }
                        } else if (this.sortOrder.includes("chrono")){
                            filteredDocuments.sort(function (a, b) {
                                return new Date(a.date) - new Date(b.date);
                            });
                            if (this.sortOrder === "chrono_reverse"){
                                filteredDocuments.reverse();
                            }
                        }
                    }
                    return filteredDocuments;
                },

                filteredFolders: function(){
                    let tmpList = this.documents.filter((doc) => {
                        let isFiltered = true;
                        if (this.selectedLanguage){
                            if (doc.language === this.selectedLanguage){
                                isFiltered = true;
                            } else {
                                return false;
                            }
                        }
                        return isFiltered;
                    });
                    this.updateChildCount(tmpList);
                    return this.documentStructure;
                }
            },
            watch: {
                q(value) {
                    console.log(value)
                    this.loadDocuments()
                    console.log(this.q)
                }
            }
        });
    }

}

export default DocumentList;
