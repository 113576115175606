import Lazy from './Lazy';

class LazyBackground extends Lazy{

    constructor(selector='.lazy-bg')   {
        super({
            targets: selector,
            loadFn: elt => {
                const imgSrc = elt.getAttribute('data-lazy');
                if(imgSrc) {
                    const img = document.createElement('img');
                    img.onload = () => {
                        elt.style.backgroundImage = 'url("'+imgSrc+'")';
                    }
                    img.src = imgSrc;
                }
            },
            threshold: 0.01,
            rootMargin: {top: 0, right: 0, left: 0, bottom: 0},
            persist: false,
            simLoadLimit: 20,
            simLoadDelay: 100,
        })
    }
}

export default LazyBackground;
